import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout'
import Container from '@/components/Container'
import AllTastes from '@/components/Alltastes'
import TextAndImage from '@/components/TextAndImage'
import PageHeading from '@/components/PageHeading'
import Wysiwyg from '@/components/Wysiwyg'
import CloudContainer from '@/components/CloudContainer'
import { seo, pagelang, wp } from '@/types.d'
import Onview from '@/components/Onview'

interface TastesTemplateData {
  data: {
    page: {
      id: string
      title: string
      content: any
      uri: string
      endHeading: string
      endButton: any
      seo: seo
      language: pagelang
      translations: any
      featuredImage: any
      endImage: any
      endContent: any
    }
    tastes: any
    mainMenu: any
    langData: any
    instagram: any
    wp: wp
    form: any
  }
}

const TastesTemplate = ({ data }: TastesTemplateData) => {
  const { tastes, page, mainMenu, wp, instagram, form } = data
  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }

  mainMenu.navButton = wp?.navButtons || ''
  const mainbutton = JSON.parse(page?.endButton)

  const reorderedTastes = tastes?.nodes
    ? [...tastes.nodes.slice(tastes.nodes.length - 1), ...tastes.nodes.slice(0, tastes.nodes.length - 1)]
    : []

  return (
    <Layout
      form={form}
      featuredImage={page?.featuredImage}
      nav={mainMenu}
      lang={langData}
      instagram={instagram}
      cloudLeft={true}
      uri={page?.uri}
      seodesc={page?.seo?.metaDesc}
      seokeywors={page?.seo?.metaKeywords}
      seotitle={page?.seo?.title}
    >
      <Container>
        <PageHeading className="max-w-[800px] mx-auto">{page.title}</PageHeading>
        <Wysiwyg classNameInner="max-w-[800px] mx-auto">{page.content}</Wysiwyg>
      </Container>
      <Container className="my-20 md:my-24">
        <Onview>
          <AllTastes alignLeft={true} tasteData={reorderedTastes} heading={''} />
        </Onview>
      </Container>
      {page?.endContent && (
        <CloudContainer className="my-20 md:my-28" block="text-image" color={`mint`} side="right">
          <Onview>
            <TextAndImage
              heading={page?.endHeading}
              alt={page?.endImage?.altText}
              link={mainbutton?.url}
              linkText={mainbutton?.anchor}
              img={page?.endImage}
              imgSide="2"
            >
              {page?.endContent}
            </TextAndImage>
          </Onview>
        </CloudContainer>
      )}
    </Layout>
  )
}

export default TastesTemplate

export const TastesTemplateQuery = graphql`
  query TastesPage($id: String!, $menu: [WpMenuLocationEnum], $translang: String, $footerForm: Int) {
    page: wpPage(id: { eq: $id }) {
      ...TastesTemplateFields
      ...featuredImagePage
      ...langdata
      ...seo
    }
    tastes: allWpGwTaste(sort: { order: ASC, fields: menuOrder }, filter: { language: { slug: { eq: $translang } } }) {
      nodes {
        title
        uri
        id
        focus: gwTasteFocus
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 350, quality: 80, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
      ...subscribe
    }
    instagram: allInstagramContent(limit: 5) {
      ...instagram
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $footerForm }) {
      ...GravityFormFields
    }
  }
`
