import React from 'react'
import Taste from '@/components/Taste'

export interface TasteDate {
  tasteData?: any
  heading?: string
  alignLeft?: boolean
}

const Alltastes = ({ tasteData }: TasteDate) => {
  if (!tasteData) {
    return null
  }

  return (
    <div className={`my-14`}>
      <div className={`grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1`}>
        {tasteData.map((item: any) => {
          return (
            <Taste
              heading={item.title}
              link={item.uri}
              focus={item?.focus}
              img={item?.featuredImage?.node?.localFile}
              key={item.id}
              alt={item?.featuredImage?.node?.altText}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Alltastes
